import { Fragment, useEffect } from "react";
import db from "../../firebase";
import styled from "styled-components";
import colors from "../../asset/style/colors";
import Card from "../../components/ui/Card/Card";
import { portfolioActions } from "../../store/portfolioSlice/portfolioSlice";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);
  const dispatch = useDispatch();
  const allProjects = useSelector((state) => state.portfolio.data);

  let projects = [];
  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection("portfolio").onSnapshot((snapshot) => {
      // eslint-disable-next-line array-callback-return
      snapshot.docs.map((doc) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        projects = [...projects, { ...doc.data() }];
      });

      dispatch(portfolioActions.setAllProject(projects));
    });
  }, []);

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Portfolio Développeur React | Bilal Ezzaaraoui Front-End freelance
        </title>
        <meta
          name="description"
          content="Portfolio - Développement et programmation de fonctionnalités et d'application web, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
        />
      </Helmet>
      <Container>
        <h1 className="main-title">
          {translate("Quelques projets séléctionnés", "Some selected projects")}
        </h1>
        <div className="portfolio-layout">
          {allProjects.length >= 1
            ? allProjects.map((item, index) => {
                return (
                  <Card
                    key={index}
                    id={item.id}
                    image={item.logo}
                    background={item.background}
                    couleur={item.couleur}
                    descriptionFr={item.descriptionFr}
                    descriptionEn={item.descriptionEn}
                    stack={item.stack}
                  />
                );
              })
            : ""}
        </div>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1.25rem 2rem;

  .main-title {
    color: ${colors.lightColor};
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .portfolio-layout {
    display: grid;
    padding: 2rem 0;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`;

export default Portfolio;
