import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

// Components
import GreenCircle from "../../components/ui/Homepage/Assets/GreenCircle";
import LogoStack from "../../components/ui/Homepage/Assets/LogoStack";
import Button from "../../components/ui/Button/Button";
import CvPurple from "../../components/ui/Homepage/CvPurple";
import SkillsTree from "../../components/ui/Homepage/SkillsTree";

// Image
import logo from "../../asset/img/Header/logo-transparent.png";
import js from "../../asset/img/Homepage/LogoStack/js.svg";
import react from "../../asset/img/Homepage/LogoStack/react.svg";
import redux from "../../asset/img/Homepage/LogoStack/redux.svg";
import typescript from "../../asset/img/Homepage/LogoStack/typescript.png";
import firebase from "../../asset/img/Homepage/LogoStack/firebase.svg";
import cypress from "../../asset/img/Homepage/LogoStack/cypress.jpeg";
import styledComponents from "../../asset/img/Homepage/LogoStack/styled-components.png";
import project from "../../asset/img/Homepage/project/screen.png";

// Icons
import { IoSpeedometer } from "react-icons/io5";
import { FaLaptopCode, FaArrowRight } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";

// Portrait
import portrait from "../../asset/img/Homepage/Bio/portrait.jpg";
import colors from "../../asset/style/colors";

const Homepage = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const boxData = [
    {
      image: IoSpeedometer,
      titleFr: "qualitatif",
      titleEn: "quality",
      descriptionFr:
        "Passez votre business au niveau supérieur avec un projet web à la pointe de la technologie, optimisé, rapide et réactif.",
      descriptionEn:
        "Take your business to the next level with a technologically advanced, optimized, fast and responsive web project.",
    },
    {
      image: FaLaptopCode,
      titleFr: "Objectif",
      titleEn: "Goal",
      descriptionFr:
        "Imaginer, concevoir et programmer des projets web en proposant des solutions techniques et automatisées.",
      descriptionEn:
        "Imagine, design and program web projects by proposing technical and automated solutions.",
    },
    {
      image: BiWorld,
      titleFr: "Organisation",
      titleEn: "Organization",
      descriptionFr:
        "À distance ou en présentiel, on communique, on programme des objectifs, on utilise des méthodes agiles.",
      descriptionEn:
        "Whether remote or face-to-face, we communicate, we program objectives, we use agile methods.",
    },
  ];

  const panelData = [
    { image: typescript, title: "TypeScript" },
    { image: js, title: "Javascript" },
    { image: react, title: "react" },
    { image: redux, title: "redux" },
    { image: firebase, title: "Firebase" },
    { image: styledComponents, title: "Styled-Components" },
  ];

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Bilal Ezzaaraoui | Développeur Front-End React JS</title>
        <meta
          name="description"
          content="Développement de fonctionnalités et d'application web, site vitrine, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
        />
      </Helmet>
      <Fragment>
        <HomepageMain>
          <img src={logo} alt="logo" className="img-logo" />
          <h1 className="title">
            Bilal Ezzaaraoui. <br />
          </h1>
          <p className="text">
            {translate(
              "Développeur Front-End React Js",
              "Front-End Developer React Js"
            )}
          </p>
        </HomepageMain>

        <FirstSection>
          <div className="big-title">
            <div className="big-title-text">
              <span></span>
              <p>{translate("ENSEMBLE", "TOGETHER")}</p>
              <span></span>
            </div>
            <h3>
              <span>{translate("DÉVELOPPONS", "LET'S DEVELOP")}</span>
              <br />
              {translate(
                "UN PRODUIT WEB INNOVANT",
                "AN INNOVATIVE WEB PRODUCT"
              )}
            </h3>
          </div>
          <div className="three-object">
            {boxData.map((item, key) => (
              <div className="box" key={key}>
                <GreenCircle icons={item.image} />
                <h3>{isEnglish ? item.titleEn : item.titleFr}</h3>
                <p>{isEnglish ? item.descriptionEn : item.descriptionFr}</p>
              </div>
            ))}
          </div>
          <div className="skills-panel">
            {panelData.map((item, key) => {
              return (
                <LogoStack key={key} logo={item.image} title={item.title} />
              );
            })}
          </div>
          <div className="bio-layout">
            <div className="bio-layout-info">
              <h3>
                {translate(
                  "Besoin d'un développeur Front-end",
                  "Need a front-end developer"
                )}
              </h3>
              <h4>
                {translate(
                  "Que pourrions-nous faire ensemble ?",
                  "What could we do together ?"
                )}
              </h4>
              <p>
                {translate(
                  "Je développe des projets web, quelles que soient leurs dimensions, de la landing page à l’application web en passant par le SaaS. J’ai une aspiration certaine pour les projets entrepreneuriaux innovants, et les méthodes agiles.",
                  "I develop web projects, whatever their size, from landing page to web application to SaaS. I have a certain aspiration for innovative entrepreneurial projects, and agile methods."
                )}
              </p>
              <ul>
                <li>
                  <FaCheck color="green" /> <span>TypeScript</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>React</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>Redux</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>Firebase</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>Testing</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>API</span>
                </li>
                <li>
                  <FaCheck color="green" /> <span>Documentation</span>
                </li>
              </ul>
              <Button
                type="router"
                title={translate("En savoir plus", "Read more")}
                path="/services"
              />
            </div>
            <div className="bio-layout-img">
              <div
                className="img-bg"
                style={{ backgroundImage: `url(${portrait})` }}
              ></div>
            </div>
          </div>
        </FirstSection>

        <SecondSection>
          <div className="section-layout">
            <div className="first-project">
              <h4>
                {translate("Projet mise en avant", "Highlighted project")}
              </h4>
              <p>
                {translate(
                  "Consulte mon portfolio et découvre l'ensemble de mes réalisations.",
                  "Consult my portfolio and discover all my achievements."
                )}
              </p>
              <div className="first-project-layout">
                <div className="first">
                  <img src={project} alt="logo" />
                </div>
                <div className="second">
                  <ul>
                    <li>
                      <img src={react} alt="logo" />
                    </li>
                    <li>
                      <img src={redux} alt="logo" />
                    </li>
                    <li>
                      <img src={firebase} alt="logo" />
                    </li>
                    <li>
                      <img src={styledComponents} alt="logo" />
                    </li>
                    <li>
                      <img src={cypress} alt="logo" />
                    </li>
                  </ul>
                  <h3>
                    {translate(
                      "Application web - Reservia",
                      "Web application - Reservia"
                    )}
                  </h3>
                  <p>
                    {translate(
                      "Reservia est une plateforme de réservation d'hébergement, c'est un projet personnel qui m'a permis de m'exercer et d'apprendre de nouvelles technologies. Étant développée From Scratch, ma responsabilité était de créer le projet avec toutes les fonctionnalités qui lui incombe.",
                      "Reservia is an accommodation booking platform, it is a personal project that allowed me to practice and learn new technologies. Being developed From Scratch, my responsibility was to create the project with all the functionalities it requires."
                    )}
                  </p>
                  <Link to="/portfolio/Reservia">
                    <FaArrowRight />
                    {translate("consulter le projet", "consult the project")}
                  </Link>
                  <div className="btn-center">
                    <Button
                      hauteur=""
                      largeur=""
                      title="Consulter tous mes projets"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SecondSection>

        <ThirdSection>
          <div className="skills-tree-layout">
            <h4>{translate("Arbre de compétences", "Skills tree")}</h4>
            <p className="line">
              {translate(
                "Zoom sur mon background de développeur Front-end",
                "Focus on my background as a front-end developer"
              )}
            </p>
            <SkillsTree />
            {false && (
              <FeesContainer>
                <h2>
                  {translate(
                    "Une tarification simple et transparente",
                    "Simple and transparent pricing"
                  )}
                </h2>
                <h3>
                  {translate(
                    "Un tarif journalier clair pour du développement spécifique",
                    "A clear daily rate for specific development"
                  )}
                </h3>
                <div className="fees-box-layout">
                  <div className="fees-box">
                    <p className="title">
                      {translate("Taux journalier", "Daily rate")}
                    </p>
                    <p className="sub-title">
                      {translate(
                        "(en vigueur depuis le 1er Sept/2022)",
                        "(effective Sept 1/2022)"
                      )}
                    </p>
                    <div className="price-container">
                      <span className="euro">{translate("€", "$")}</span>
                      <span className="amount">290</span>
                      <span className="day">{translate("/jour", "/day")}</span>
                    </div>
                  </div>
                </div>
              </FeesContainer>
            )}
            <CvPurple />
          </div>
        </ThirdSection>
      </Fragment>
    </Fragment>
  );
};

const HomepageMain = styled.div`
  max-width: 80rem;
  margin: 4rem auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 420px) {
    margin: 3rem auto;
  }

  .img-logo {
    width: 14rem;

    @media (max-width: 1280px) {
      width: 10rem;
    }

    @media (max-width: 768px) {
      width: 8rem;
    }
  }

  .title {
    color: ${colors.lightColor};
    font-size: 3rem;
    font-weight: bold;
    margin: 0.5rem 0 0;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 420px) {
      font-size: 1.5rem;
    }
  }

  .text {
    color: ${colors.greyColor};
    font-size: 2rem;
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (max-width: 420px) {
      font-size: 1rem;
    }
  }
`;

const FirstSection = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 1rem 1.25rem 0;

  .big-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;

    .big-title-text {
      display: flex;
      align-items: center;

      span {
        border-top: 2px solid ${colors.greenColor};
        height: 0.5px;
        width: 10rem;

        @media (max-width: 575px) {
          width: 6rem;
        }

        @media (max-width: 390px) {
          width: 4.3rem;
        }
      }

      p {
        color: ${colors.lightColor};
        font-size: 3rem;
        font-weight: bold;
        padding: 0 0.5rem;

        @media (max-width: 768px) {
          font-size: 2rem;
        }

        @media (max-width: 575px) {
          font-size: 1.6rem;
        }
      }
    }

    h3 {
      color: ${colors.lightColor};
      font-size: 2.6rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.2;
      margin: -1rem 0;

      @media (max-width: 768px) {
        font-size: 2.3rem;
        margin: -0.5rem 0;
      }

      @media (max-width: 575px) {
        font-size: 1.6rem;
      }

      span {
        font-size: 5.1rem;

        @media (max-width: 768px) {
          font-size: 4.5rem;
        }

        @media (max-width: 575px) {
          font-size: 3rem;
        }

        @media (max-width: 390px) {
          font-size: 2.5rem;
        }
      }
    }
  }

  .three-object {
    margin: 7rem 0 5rem;
    display: flex;
    justify-content: space-between;
    column-gap: 8rem;

    @media (max-width: 1280px) {
      column-gap: 4rem;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 2.5rem;
      margin: 4rem 0;
    }

    .box {
      text-align: center;

      h3 {
        color: ${colors.lightColor};
        margin: 0.5rem 0;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      p {
        font-size: 1.1rem;
        color: ${colors.greyColor};
      }
    }
  }

  .skills-panel {
    border-top: 1px solid rgba(140, 140, 140, 0.3);
    border-bottom: 1px solid rgba(140, 140, 140, 0.3);
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-end;

    @media (max-width: 768px) {
      column-gap: 1.2rem;
      row-gap: 1rem;
    }
  }

  .bio-layout {
    padding: 8rem 0;
    display: flex;

    @media (max-width: 768px) {
      padding: 4rem 0;
    }

    &-info {
      width: 60%;

      @media (max-width: 768px) {
        width: 100%;
      }

      h3 {
        color: ${colors.greenColor};
        font-size: 2rem;
        font-weight: bold;

        @media (max-width: 390px) {
          font-size: 1.5rem;
        }
      }

      h4 {
        color: ${colors.lightColor};
        font-weight: bold;
        font-size: 1.2rem;
        padding-top: 1rem;

        @media (max-width: 390px) {
          font-size: 1.2rem;
        }
      }

      p {
        padding: 1rem 5rem 1rem 0;
        color: ${colors.greyColor};
        font-size: 1.1rem;

        @media (max-width: 768px) {
          padding: 1rem 0;
          font-size: 1rem;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        li {
          display: flex;
          align-items: center;
          background-color: ${colors.navyColor};
          padding: 0.3rem 0.4rem;
          font-weight: bold;
          color: ${colors.lightColor};
          margin-bottom: 0.3rem;
          margin-right: 0.8rem;
          font-size: 0.9rem;
          border-radius: 3px;
          transition: 0.1s;

          &:hover {
            transform: scale(1.03);
          }

          svg {
            font-size: 0.8rem;
            margin-right: 5px;
          }
        }
      }
    }

    &-img {
      width: 40%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        display: none;
      }

      .img-bg {
        width: 15rem;
        height: 15rem;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        border: 4px solid ${colors.greenColor};
      }
    }
  }
`;

const SecondSection = styled.div`
  border-top: 1px solid rgba(140, 140, 140, 0.3);
  border-bottom: 1px solid rgba(140, 140, 140, 0.3);

  .section-layout {
    max-width: 80rem;
    margin: 4rem auto;
    padding: 1rem 1.25rem;

    @media (max-width: 390px) {
      font-size: 1.6rem;
      padding: 0 1.25rem;
    }

    .first-project {
      h4 {
        color: ${colors.lightColor};
        font-size: 2.5rem;
        font-weight: bold;

        @media (max-width: 390px) {
          font-size: 1.6rem;
        }
      }

      p {
        color: ${colors.greyColor};
        font-size: 1.1rem;

        @media (max-width: 890px) {
          margin-bottom: 0.5rem;
        }
      }

      &-layout {
        margin-top: 1.5rem;
        display: flex;

        @media (max-width: 890px) {
          display: block;
        }
        .first {
          width: 50%;
          display: flex;
          align-items: flex-start;
          padding-right: 3rem;

          @media (max-width: 890px) {
            width: 100%;
          }

          img {
            width: 100%;
          }
        }
        .second {
          width: 50%;
          padding-left: 3rem;

          @media (max-width: 890px) {
            width: 100%;
            padding: 1.5rem 0;
          }

          @media (max-width: 390px) {
            padding: 2rem 0 1.5rem;
          }

          ul {
            display: flex;
            align-items: center;

            li {
              margin-right: 1.3rem;
              img {
                width: 1.5rem;

                @media (max-width: 890px) {
                  width: 2rem;
                  margin-bottom: 0.5rem;
                }
              }
            }
          }

          h3 {
            color: ${colors.lightColor};
            font-size: 1.5rem;
            font-weight: bold;

            @media (max-width: 890px) {
              margin-bottom: 0.5rem;
            }
          }

          a {
            color: ${colors.greenColor};
            transition: 0.3s;
            display: flex;
            align-items: center;
            column-gap: 5px;
            text-transform: capitalize;
            margin-top: 1rem;

            @media (max-width: 390px) {
              font-size: 1rem;
            }

            svg {
              font-size: 0.9rem;
            }

            &:hover {
              color: ${colors.greyColor};
            }
          }
        }
      }
    }
  }
`;

const ThirdSection = styled.div`
  .skills-tree-layout {
    max-width: 80rem;
    margin: 8rem auto 0;
    padding: 0 1.25rem;

    @media (max-width: 768px) {
      margin: 3rem auto 0;
    }

    h4 {
      color: ${colors.lightColor};
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .line {
      color: ${colors.greyColor};
      font-size: 1.15rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
`;

const FeesContainer = styled.div`
  border-top: 1px solid rgba(140, 140, 140, 0.3);
  padding: 5rem 0;

  @media (max-width: 768px) {
    padding: 3rem 0;
  }

  h2 {
    text-align: center;
    font-size: 2.25rem;
    color: ${colors.lightColor};
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    text-align: center;
    font-size: 1.3rem;
    color: ${colors.greyColor};

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .fees-box-layout {
    display: flex;
    justify-content: center;
    .fees-box {
      margin: 1.5rem 0;
      padding: 2rem 0;
      width: 60%;
      background-color: ${colors.navyColor};
      border-radius: 8px;

      @media (max-width: 768px) {
        width: 100%;
      }

      .title {
        color: ${colors.greenColor};
        font-weight: bold;
        text-align: center;
        font-size: 1.5rem;

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      .sub-title {
        color: ${colors.greyColor};
        text-align: center;

        @media (max-width: 768px) {
          font-weight: 300;
        }
      }

      .price-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .euro {
          color: ${colors.lightColor};
          font-weight: bold;
          font-size: 2rem;
          margin-right: 0.3rem;
        }

        .amount {
          color: ${colors.lightColor};
          font-weight: bold;
          font-size: 4rem;
        }

        .day {
          color: ${colors.lightColor};
          font-size: 1.4rem;
          margin-left: 0.3rem;
        }
      }
    }
  }
`;

export default Homepage;
