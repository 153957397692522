import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAjgasyYAxPiIUqptGPn0IX_qXkEPjUbPE",
  authDomain: "portfolio-e8f97.firebaseapp.com",
  databaseURL:
    "https://portfolio-e8f97-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-e8f97",
  storageBucket: "portfolio-e8f97.appspot.com",
  messagingSenderId: "567836742022",
  appId: "1:567836742022:web:54f1a9f82bff7254d29c07",
  measurementId: "G-5119CJ38TH",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default db;
