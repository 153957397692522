import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "../../pages/Accueil/Homepage";
import Skills from "../../pages/Competences/Skills";
import Contact from "../../pages/Contact/Contact";
import Error from "../../pages/Erreur/Error";
import Services from "../../pages/MesServices/Services";
import Portfolio from "../../pages/Portfolio/Portfolio";
import Project from "../../pages/Projet/Project";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/portfolio/:id" element={<Project />} />
      <Route path="/competences" element={<Skills />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/error" element={<Error />} />
      <Route path="/*" element={<Navigate to="/error" replace />} />
    </Routes>
  );
};

export default Router;
