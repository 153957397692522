import { Fragment, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      <Container>
        <SliderLayout>
          <ContainerSlider {...settings}>
            {images.map((index, key) => (
              <Wrap key={key}>
                <img src={index} alt="Img de l'hébergement" />
              </Wrap>
            ))}
          </ContainerSlider>
        </SliderLayout>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: white; */
  margin: 4rem 0;
  z-index: 400;
  display: flex;
  flex-direction: column;
`;

const SliderLayout = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerSlider = styled(Slider)`
  width: 80%;
  height: 60vh;
  .slick-arrow {
    z-index: 100;
  }
  .slick-dots {
    li {
      width: 15px;
      &.slick-active {
        button:before {
          color: white;
          font-size: 11px;
        }
      }
      margin: 0 0px;
      button {
        &:before {
          margin-top: 0.5rem;
          font-size: 7px;
          color: #d3d3d3;
          opacity: 1;
        }
      }
    }
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide {
    visibility: hidden;
  }
  .slick-current {
    visibility: visible !important;
  }
`;

const Wrap = styled.div`
  width: 100%;
  height: 60vh;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    object-fit: contain;
  }
`;

export default ProjectSlider;
