import styled from "styled-components";
import colors from "../../../../asset/style/colors";

const GreenCircle = (props) => {
  return (
    <Container>
      <props.icons />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  padding: 1rem;
  border-radius: 50px;
  border: 2px solid ${colors.greenColor};

  svg {
    font-size: 1.5rem;
    color: ${colors.lightColor};
  }
`;

export default GreenCircle;
