import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../asset/style/colors";
import { useSelector } from "react-redux";

const Error = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Bilal Ezzaaraoui | Développeur Front-End React Freelance - TJM 290/j
        </title>
        <meta
          name="description"
          content="Développement de fonctionnalités et d'application web, site vitrine, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
        />
      </Helmet>
      <Container>
        <h1>Erreur 404</h1>
        <p>
          {translate(
            "Oups! La page que vous demandez n'existe pas.",
            "Oops, the page you requested does not exist."
          )}
        </p>
        <Link to="/" className="btn-cv">
          Retourner sur la page d’accueil
        </Link>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${colors.lightColor};
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  p {
    color: ${colors.greyColor};
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
  }

  .btn-cv {
    color: ${colors.greenColor};
    font-size: 1rem;
    border: 2px solid ${colors.greenColor};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: 0.3s;
    margin-top: 2rem;

    &:hover {
      background-color: ${colors.greenColor};
      color: black;
    }
  }
`;

export default Error;
