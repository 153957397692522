import "./CvPurple.scss";
import bg from "../../../asset/img/abstract.svg";
import french from "../../../asset/cv/french.pdf";
import english from "../../../asset/cv/english.pdf";
import { FaFileDownload } from "react-icons/fa";
import colors from "../../../asset/style/colors";
import { useSelector } from "react-redux";

const CvPurple = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);
  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };
  return (
    <div
      className="cv-download"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundColor: `${colors.greenBg}`,
      }}
    >
      <div className="text-container">
        <p className="title" style={{ color: `${colors.lightColor}` }}>
          {translate(
            "CV développeur frontend react.js",
            "CV frontend developer react.js"
          )}
        </p>
        <p className="sub-title" style={{ color: `${colors.lightColor}` }}>
          {translate(
            "Besoin d'en savoir plus sur mon parcours, télécharge mon CV en ligne.",
            "Need to know more about my background, download my CV online."
          )}
        </p>
      </div>
      <div className="cv-container">
        <a
          href={isEnglish ? english : french}
          download={
            isEnglish ? "Resume Bilal Ezzaaraoui" : "Cv Bilal Ezzaaraoui"
          }
        >
          <FaFileDownload />
          {translate("Télécharger", "Download")}
        </a>
      </div>
    </div>
  );
};

export default CvPurple;
