import "./Button.scss";
import { Link } from "react-router-dom";
const Button = (props) => {
  if (props.type === "router") {
    return (
      <Link
        to={props.path}
        className="btn-link px-8 py-2 rounded-sm capitalize text-white text-base 
        "
      >
        <span>{props.title}</span>
      </Link>
    );
  }

  if (props.type === "lien") {
    return (
      <a
        href={props.path}
        className="btn-link px-8 py-2 rounded-sm capitalize text-white text-base 
        "
      >
        <span>{props.title}</span>
      </a>
    );
  }
};

export default Button;
