import "./LogoStack.scss";

const LogoStack = (props) => {
  return (
    <div className="logo-stack">
      <img src={props.logo} alt="logo" />
      <p>{props.title}</p>
    </div>
  );
};

export default LogoStack;
