import { configureStore } from "@reduxjs/toolkit";
import PortfolioReducer from "./portfolioSlice/portfolioSlice";
import LanguageReducer from "./languageSlice/languageSlice";

const store = configureStore({
  reducer: {
    portfolio: PortfolioReducer,
    language: LanguageReducer,
  },
});

export default store;
