/* eslint-disable array-callback-return */
import "./Card.scss";
import react from "../../../asset/img/Homepage/LogoStack/react.svg";
import { Link } from "react-router-dom";
import firebase from "../../../asset/img/Homepage/LogoStack/firebase.svg";
import redux from "../../../asset/img/Homepage/LogoStack/redux.svg";
import jest from "../../../asset/img/Homepage/LogoStack/jest.png";
import sass from "../../../asset/img/Homepage/LogoStack/sass.svg";
import styledComponents from "../../../asset/img/Homepage/LogoStack/styled-components.png";
import typescript from "../../../asset/img/Homepage/LogoStack/typescript.png";
import rtl from "../../../asset/img/Homepage/LogoStack/rtl.png";
import cypress from "../../../asset/img/Homepage/LogoStack/cypress.jpeg";
import html from "../../../asset/img/Homepage/LogoStack/html.svg";
import js from "../../../asset/img/Homepage/LogoStack/js.svg";
import { useSelector } from "react-redux";

const Card = (props) => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  const stackImage = [
    { text: "react", image: react },
    { text: "html", image: html },
    { text: "javascript", image: js },
    { text: "firebase", image: firebase },
    { text: "redux", image: redux },
    { text: "jest", image: jest },
    { text: "react testing library", image: rtl },
    { text: "typescript", image: typescript },
    { text: "cypress", image: cypress },
    { text: "sass", image: sass },
    { text: "styled-components", image: styledComponents },
  ];

  return (
    <Link to={`/portfolio/${props.id}`}>
      <div className="card">
        <div className="image" style={{ backgroundColor: props.couleur }}>
          <img src={props.image} alt="logo" />
        </div>
        <div className="text">
          <h3>{props.id}</h3>
          <p>{isEnglish ? props.descriptionEn : props.descriptionFr}</p>
          <ul>
            {props.stack.map((element, index) => {
              const res = stackImage.find((item) => {
                if (item.text === element) {
                  return item;
                }
              });

              if (res !== undefined) {
                return (
                  <li key={index}>
                    <div>
                      <img src={res.image} alt="logo" />
                    </div>
                    <div>
                      <span>{res.text}</span>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </Link>
  );
};

export default Card;
