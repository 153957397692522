import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import color from "../../asset/style/colors";
import ContactForm from "../../components/ui/ContactForm/ContactForm";
import { IoIosMail } from "react-icons/io";
import { useSelector } from "react-redux";

const Contact = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          Contact Bilal Ezzaaraoui | Développeur Front-End React Freelance
        </title>
        <meta
          name="description"
          content="Contact - Développement et programmation de fonctionnalités et d'application web, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
        />
      </Helmet>
      <Container>
        <h1 className="contact-title">
          {translate(`CONTACTE-MOI`, `CONTACT ME`)}
        </h1>
        <h2 className="contact-sub-title">
          {translate(
            `Brise le plafond de verre qui t'empêche de te démarquer`,
            `Break the glass ceiling that prevents you from standing out`
          )}
        </h2>
        <p className="contact-sentence">
          {translate(
            `Envie de me présenter un projet, ou tout simplement dire bonjour ?`,
            `Want to introduce me to a project, or just say hello?`
          )}
        </p>
        <div className="contact-layout">
          <div className="contact-logo">
            <IoIosMail color="white" />
          </div>
          <div className="contact-form">
            <ContactForm />
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1.25rem 0;

  .contact-title {
    color: ${color.greenColor};
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      margin-top: 0;
    }
  }

  .contact-sub-title {
    font-size: 1.8rem;
    color: ${color.lightColor};
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  .contact-sentence {
    font-size: 1.1rem;
    color: ${color.greyColor};
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .contact-layout {
    margin: 3rem 0 6rem;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 2rem 0 4rem;
    }

    .contact-logo {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      svg {
        font-size: 10rem;

        @media (max-width: 768px) {
          font-size: 5rem;
        }
      }
    }

    .contact-form {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export default Contact;
