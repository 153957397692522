import styled from "styled-components";
// Image
import html from "../../../asset/img/Homepage/LogoStack/html.svg";
import css from "../../../asset/img/Homepage/LogoStack/css.svg";
import sass from "../../../asset/img/Homepage/LogoStack/sass.svg";
import styledComponents from "../../../asset/img/Homepage/LogoStack/styled-components.png";
import js from "../../../asset/img/Homepage/LogoStack/js.svg";
import react from "../../../asset/img/Homepage/LogoStack/react.svg";
import redux from "../../../asset/img/Homepage/LogoStack/redux.svg";
import typescript from "../../../asset/img/Homepage/LogoStack/typescript.png";
import jest from "../../../asset/img/Homepage/LogoStack/jest.png";
import rtl from "../../../asset/img/Homepage/LogoStack/rtl.png";
import cypress from "../../../asset/img/Homepage/LogoStack/cypress.jpeg";
import firebase from "../../../asset/img/Homepage/LogoStack/firebase.svg";
import colors from "../../../asset/style/colors";
import { useSelector } from "react-redux";

const SkillsTree = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };
  return (
    <Tree>
      <FirstLine>
        <div className="web">
          <h3 className="title">
            {translate("Intégration Web", "Web Integration")}
          </h3>
          <div className="web-layout">
            <div className="html">
              <img src={html} alt="logo" />
              <h3>Html</h3>
            </div>
            <div className="css">
              <img src={css} alt="logo" />
              <h3>Css</h3>
            </div>
          </div>
        </div>
        <div className="center-layout">
          <div className="round"></div>
          <div className="line"></div>
        </div>
        <div className="graphic">
          <h3 className="title">{translate("Outils CSS", "CSS Tools")}</h3>
          <div className="graphic-layout">
            <div className="sass">
              <img src={sass} alt="logo" />
              <h3>Sass</h3>
            </div>
            <div className="styled-components">
              <img src={styledComponents} alt="logo" />
              <h3>Styled components</h3>
            </div>
          </div>
        </div>
      </FirstLine>
      <SecondLine>
        <div className="first-line-section">
          <div className="first-part">
            <div className="round"></div>
            <div className="line"></div>
          </div>
        </div>
        <div className="second-line-section">
          <div className="second-one">
            <div className="line"></div>

            <div className="round-space">
              <div className="round"></div>
            </div>
            <div className="line"></div>
          </div>
        </div>
        <div className="third-line-section">
          <div className="third-one">
            <div className="round-space">
              <div className="round"></div>
            </div>
            <div className="line"></div>
          </div>
        </div>
      </SecondLine>
      <ThirdSection>
        <div className="first-line-section">
          <div className="first-part">
            <div className="line"></div>
          </div>
        </div>
        <div className="second-line-section">
          <div className="second-one">
            <div className="line"></div>
          </div>
        </div>
        <div className="third-line-section">
          <div className="third-one">
            <div className="line"></div>
          </div>
        </div>
      </ThirdSection>
      <FourthSection>
        <div className="framework">
          <h3 className="title">Front-End</h3>
          <div className="framework-layout">
            <div className="js">
              <img src={js} alt="logo" />
              <h3>JavaScript</h3>
            </div>
            <div className="typescript">
              <img src={typescript} alt="logo" />
              <h3>TypeScript</h3>
            </div>
            <div className="react">
              <img src={react} alt="logo" />
              <h3>React</h3>
            </div>
            <div className="redux">
              <img src={redux} alt="logo" />
              <h3>Redux</h3>
            </div>
          </div>
        </div>
        <div className="center-layout">
          <div className="line"></div>
        </div>
        <div className="graphic">
          <h3 className="title">{translate("Test technique", "Testing")}</h3>
          <div className="graphic-layout">
            <div className="figma">
              <img src={jest} alt="logo" />
              <h3>Jest</h3>
            </div>
            <div className="photoshop">
              <img src={rtl} alt="logo" />
              <h3>React testing library</h3>
            </div>
            <div className="photoshop">
              <img src={cypress} alt="logo" />
              <h3>Cypress</h3>
            </div>
          </div>
        </div>
      </FourthSection>
      <FifthSection>
        <div className="line"></div>
        <div className="round-space">
          <div className="round"></div>
        </div>
      </FifthSection>
      <SixthSection>
        <h3 className="title">Back-End</h3>
        <div className="html">
          <img src={firebase} alt="logo" />
          <h3>Firebase</h3>
        </div>
      </SixthSection>
    </Tree>
  );
};

const Tree = styled.div`
  margin: 5rem 0;

  @media (max-width: 768px) {
    margin: 3rem 0;
  }
`;

const FirstLine = styled.div`
  width: 100%;
  display: flex;
  .web {
    width: 40%;

    .title {
      color: ${colors.lightColor};
      text-align: center;
      font-size: 1.2rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &-layout {
      display: flex;
      padding: 2rem 0;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
      }

      h3 {
        padding-top: 0.6rem;
        color: ${colors.lightColor};
        text-align: center;
        text-transform: capitalize;
        font-size: 1.2rem;
      }

      .html {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: capitalize;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 2.5rem;
          }
        }
      }

      .css {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: uppercase;
        }

        img {
          width: 4.5rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }
      }
    }
  }
  .center-layout {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .round {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: ${colors.greenColor};
      margin-bottom: 0.5rem;
      animation: pulse-red 2s infinite;
    }

    @keyframes pulse-red {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(209, 55, 55, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(59, 82, 231, 0);
      }
    }
    .line {
      width: 1px;
      height: 97%;
      background-color: rgba(90, 103, 216, 0.4);
    }
  }
  .graphic {
    width: 40%;

    .title {
      color: ${colors.lightColor};
      text-align: center;
      font-size: 1.2rem;
      text-transform: capitalize;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &-layout {
      display: flex;
      padding: 2rem 0;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
      }

      .figma {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 3rem;

          @media (max-width: 768px) {
            width: 1.8rem;
          }
        }

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }
      }

      .sass {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 5.5rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }
      }

      .styled-components {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 4.2rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }
      }

      .photoshop {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 4.5rem;

          @media (max-width: 768px) {
            width: 2.5rem;
          }
        }

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }
      }
    }
  }
`;

const SecondLine = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;

  .first-line-section {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .first-part {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .round {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: ${colors.greenColor};
        animation: pulse-red 2s infinite;
        margin: 0 -0.45rem;
      }

      @keyframes pulse-red {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(209, 55, 55, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(59, 82, 231, 0);
        }
      }

      .line {
        width: 93%;
        height: 1px;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }

  .second-line-section {
    width: 20%;
    height: 100%;

    .second-one {
      height: 100%;
      display: flex;
      align-items: center;

      .round-space {
        padding: 0 1rem;

        .round {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: ${colors.greenColor};
          animation: pulse-red 2s infinite;
        }
      }

      @keyframes pulse-red {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(209, 55, 55, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(59, 82, 231, 0);
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }

  .third-line-section {
    width: 40%;
    height: 100%;

    .third-one {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      .round-space {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin-right: -0.3rem;
        .round {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: ${colors.greenColor};
          animation: pulse-red 2s infinite;
        }
      }

      @keyframes pulse-red {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(209, 55, 55, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(59, 82, 231, 0);
        }
      }

      .line {
        width: 93%;
        height: 1px;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }
`;

const ThirdSection = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;

  .first-line-section {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .first-part {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line {
        width: 1px;
        height: 100%;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }

  .second-line-section {
    width: 20%;
    height: 100%;

    .second-one {
      height: 100%;
      display: flex;
      justify-content: center;

      .line {
        width: 1px;
        height: 100%;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }

  .third-line-section {
    width: 40%;
    height: 100%;

    .third-one {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        width: 1px;
        height: 100%;
        background-color: rgba(90, 103, 216, 0.4);
      }
    }
  }
`;

const FourthSection = styled.div`
  width: 100%;
  display: flex;
  .framework {
    width: 40%;

    .title {
      color: ${colors.lightColor};
      text-align: center;
      padding-top: 0.6rem;
      font-size: 1.2rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &-layout {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 2rem 0;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
      }

      h3 {
        padding-top: 0.6rem;
        color: ${colors.lightColor};
        text-align: center;
        text-transform: capitalize;
      }

      .js {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: capitalize;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 2.5rem;
          }
        }
      }

      .typescript {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: capitalize;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 2.5rem;
          }
        }
      }

      .react {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: capitalize;
          padding: 0;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }
      }

      .redux {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1rem;
          text-transform: capitalize;
          padding: 0.8rem 0 0 0;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }
      }
    }
  }
  .center-layout {
    width: 20%;
    display: flex;
    justify-content: center;

    .line {
      width: 1px;
      height: 100%;
      background-color: rgba(90, 103, 216, 0.4);
    }
  }
  .graphic {
    width: 40%;

    .title {
      color: ${colors.lightColor};
      text-align: center;
      text-transform: capitalize;
      font-size: 1.2rem;
      padding-top: 0.6rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &-layout {
      display: flex;
      padding: 2rem 0;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
      }

      .figma {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }
      }

      .photoshop {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          color: ${colors.lightColor};
          text-align: center;
          padding-top: 0.6rem;
          text-transform: capitalize;
        }

        img {
          width: 4rem;

          @media (max-width: 768px) {
            width: 3rem;
          }
        }
      }
    }
  }
`;

const FifthSection = styled.div`
  width: 100%;
  height: 5rem;
  padding: 0.2rem 0 0 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;

  .round-space {
    .round {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: ${colors.greenColor};
      margin-bottom: 0.3rem;
      animation: pulse-red 2s infinite;
    }
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(209, 55, 55, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(59, 82, 231, 0);
    }
  }

  .line {
    width: 1px;
    height: 100%;
    background-color: rgba(90, 103, 216, 0.4);
  }
`;

const SixthSection = styled.div`
  width: 100%;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    color: ${colors.lightColor} !important;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .html {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: ${colors.lightColor};
      text-align: center;
      font-size: 1rem;
      padding-top: 0.5rem;
      text-transform: capitalize;
    }

    img {
      width: 3.5rem;

      @media (max-width: 768px) {
        width: 2rem;
      }
    }
  }
`;

export default SkillsTree;
