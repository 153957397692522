import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import colors from "../../asset/style/colors";
import GreenCircle from "../../components/ui/Homepage/Assets/GreenCircle";
import {
  FaArrowRight,
  FaPaintBrush,
  FaLaptopCode,
  FaCode,
} from "react-icons/fa";
import { useSelector } from "react-redux";

const Services = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Mes services | développement, intégration d'interface web front React
        </title>
        <meta
          name="description"
          content="Mes services - React - Développement et programmation de site et d'application web, Intégration de maquette sous react, Réalisation de maquette web, développement de fonctionnalités."
        />
      </Helmet>
      <Fragment>
        <Main>
          <h1 className="title">
            {translate("PRESTATIONS DE SERVICES", "SERVICES")}
          </h1>
          <h2 className="sub-title">
            {translate(
              "Développeur web front-end React",
              "React front-end web developer"
            )}
          </h2>
          <h3 className="sentence">
            {translate(
              "Une mission, programmer des projets web en proposant des solutions techniques et automatisées",
              "A mission, to program web projects by proposing technical and and automated solutions"
            )}
          </h3>

          <div className="service-layout">
            <h3>
              {translate(
                "FAISONS UN PEU LE TOUR DE MES SERVICES",
                "LET'S HAVE A LOOK AT MY SERVICES"
              )}
            </h3>
            <h4 className="first-title">
              {translate(
                "Que pourrions-nous réaliser ensemble ?",
                "What could we achieve together?"
              )}
            </h4>
            <div className="service-layout-box">
              <div className="box">
                <GreenCircle icons={FaPaintBrush} />
                <h4>{translate("Identité graphique", "Graphic identity")}</h4>
                <p>
                  {translate(
                    "Réalisation intégrale d'une maquette et d'une charte graphique prête à être exploitée ou d'éléments ponctuels au sein d'un projet web, je communique par le graphisme une véritable identité.",
                    "Complete realization of a template and a graphic charter ready to be exploited or of punctual elements within a project, I communicate through graphics a real identity."
                  )}
                </p>
              </div>
              <div className="box">
                <GreenCircle icons={FaLaptopCode} />
                <h4>{translate("Intégration web", "Web integration")}</h4>
                <p>
                  {translate(
                    "Développement et intégration d'une maquette graphique pour la convertir en un projet web conforme, je porte une attention particulière à l'expérience utilisateur et à l'optimisation (SEO & Rapidité d'affichage) sur l'ensemble de mes projets.",
                    "Development and integration of a graphic template to convert it into a into a compliant web project, I pay particular attention to the user experience and optimization (SEO and speed of display) on all my projects."
                  )}
                </p>
              </div>
              <div className="box">
                <GreenCircle icons={FaCode} />
                <h4>
                  {translate("Développement", "Web application development")}
                </h4>
                <p>
                  {translate(
                    "Poursuivre ou convertir un projet déjà existant dans le cadre d'une mission définie, seule où au sein d'une équipe, je m'intègre facilement.",
                    "To continue or convert an already existing project within the framework of a defined mission, alone or within a team, I integrate and adapt easily."
                  )}
                </p>
              </div>
            </div>
          </div>
        </Main>
        <Second>
          <div className="service-text-layout">
            <h4>
              {translate(
                "QUELS TYPES DE DÉVELOPPEUR SUIS-JE ?",
                "WHAT KIND OF DEVELOPER AM I?"
              )}
            </h4>
            <h5>
              {translate(
                "Existe-t-il vraiment plusieurs types de développeurs ?",
                "Are there really different types of developers?"
              )}
            </h5>
            <p className="service-sentence">
              {translate(
                "Je suis développeur web Front-End spécialisé sur le langage de programmation React, un langage qui permettra à ton projet d'être extrêmement rapide, et facilement maintenable, la recette idéale pour faire la différence de nos jours, sur tous les points de vue.",
                "I am a Front-End web developer specialized on the React programming language programming language React, a language that will allow your project to be extremely fast, and easily maintainable, the ideal recipe to make the difference nowadays, on all points of view."
              )}
            </p>
            <p className="service-sentence">
              {translate(
                "Front-End signifie que le développeur se focalise exclusivement sur la partie visible de l'iceberg, autrement dit la partie visible sur les écrans de navigations, c'est d'ailleurs sur ce front, que je suis le meilleur... Un projet web a deux dimensions le Front-End que l'on vient de voir et le back-End (la partie donnée & serveur).",
                "Front-End means that the developer focuses exclusively on the visible part of the iceberg, in other words the part visible on the screens of navigations, it is besides on this front, that I am the best... A web project has two dimensions : the Front-End that we just saw and the back-End (the data & server part)."
              )}
            </p>
            <p className="service-sentence">
              {translate(
                `De manière très générale, je suis le bon partenaire pour créer tous types de projets web, que ce soit la simple création d'un site internet à l'application web, cependant mon domaine de compétence encadre des projets se voulant "sur mesure" "connectés a d'autres services (API)" mais surtout "a la pointe de la technologie".`,
                `In a very general way, I am the right partner to create all types of web projects, whether it is the simple creation of a website to the web application, however my area of expertise frames projects that are "custom" "connected to other services (API)" but especially "at the cutting edge of technology".`
              )}
            </p>
          </div>
        </Second>
        <Second>
          <div className="service-text-layout">
            <div className="portfolio">
              <div className="text-container">
                <p className="title">
                  {translate(
                    "Consulte toutes mes projets !",
                    "Check out all my projects!"
                  )}
                </p>
                <p className="sub-title">
                  {translate(
                    "Mes projets sont précieux, je les chouchoute, ils sont détaillés et comportent de véritables avis...",
                    "My projects are precious, I cherish them, they are tested and well commented."
                  )}
                </p>
              </div>
              <div className="portfolio-container">
                <Link to="/portfolio">
                  <FaArrowRight />
                  {translate("Découvrir", "Discover")}
                </Link>
              </div>
            </div>
          </div>
        </Second>
      </Fragment>
    </Fragment>
  );
};

const Main = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1.25rem 0;

  .title {
    font-size: 1.25rem;
    color: ${colors.lightColor};
    text-align: center;
    font-weight: bold;
    margin: 2rem 0 0;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .sub-title {
    font-size: 1.875rem;
    color: ${colors.greyColor};
    text-align: center;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .sentence {
    color: ${colors.greenColor};
    text-align: center;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  .service-layout {
    margin-top: 4rem;

    h3 {
      color: ${colors.lightColor};
      font-weight: bold;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .first-title {
      color: ${colors.greyColor};
      font-size: 1.875rem;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 1rem;
        text-align: center;
      }
    }

    .service-layout-box {
      display: flex;
      gap: 3rem;
      margin: 2rem 0 6rem;
      justify-content: flex-start;

      @media (max-width: 768px) {
        margin: 2rem 0 3rem;
        padding: 0 1rem;
        flex-direction: column;
        gap: 2rem;
      }

      .box {
        background-color: ${colors.navyColor};
        border-radius: 5px;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s ease-in-out;

        @media (max-width: 768px) {
          padding: 2rem 1rem;
        }

        &:hover {
          transform: translateY(-5px);
        }

        .circle {
          margin-bottom: 1rem;
        }

        h4 {
          color: ${colors.lightColor};
          font-weight: bold;
          font-size: 1.2rem;
          margin: 0.5rem 0;
          text-align: center;
        }

        p {
          color: ${colors.greyColor};
          text-align: center;
        }
      }
    }
  }
`;

const Second = styled.div`
  border-top: 1px solid rgba(140, 140, 140, 0.3);

  .service-text-layout {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 1.25rem;

    @media (max-width: 768px) {
      padding: 3rem 1rem;
    }

    h4 {
      color: ${colors.greenColor};
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    h5 {
      color: ${colors.lightColor};
      text-align: center;
      font-weight: bold;
      font-size: 1.875rem;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }

    .service-sentence {
      color: ${colors.greyColor};
      text-align: center;
      padding: 0 10%;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        padding: 0;
      }
    }

    .portfolio {
      padding: 5rem 4rem;
      background-color: ${colors.greenBg};
      background-repeat: no-repeat;
      background-position: top right;
      border-radius: 3px;
      display: flex;

      @media (max-width: 768px) {
        padding: 1rem 1rem;
        flex-direction: column;
      }

      .text-container {
        width: 70%;

        @media (max-width: 768px) {
          width: 100%;
        }

        .title {
          color: ${colors.lightColor};
          font-weight: bold;
          font-size: 3rem;

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }

        .sub-title {
          color: ${colors.greyColor};
          padding-right: 5rem;

          @media (max-width: 768px) {
            padding-right: 0;
          }
        }
      }

      .portfolio-container {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @media (max-width: 768px) {
          width: 100%;
          justify-content: flex-start;
          padding-top: 1rem;
        }

        a {
          display: flex;
          align-items: center;
          padding: 1rem 3rem;
          color: ${colors.greenColor};
          border: 2px solid ${colors.greenColor};
          border-radius: 3px;
          cursor: pointer;
          transition: 0.3s;
          font-weight: bold;

          svg {
            color: ${colors.greenColor};
            margin-right: 0.5rem;
          }

          &:hover {
            background-color: ${colors.greenColor};
            color: black;

            svg {
              color: black;
            }
          }
        }
      }
    }
  }
`;

export default Services;
