import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import colors from "../../asset/style/colors";
import SkillsTree from "../../components/ui/Homepage/SkillsTree";
import gif from "../../asset/img/Skills/login.png";
import { FiTarget } from "react-icons/fi";
import { BsLightbulbFill } from "react-icons/bs";
import CvPurple from "../../components/ui/Homepage/CvPurple";
import french from "../../asset/cv/french.pdf";
import english from "../../asset/cv/english.pdf";
import { useSelector } from "react-redux";

const Skills = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Zoom sur mes compétences de développeur - Bilal Ezzaaraoui Front End
        </title>
        <meta
          name="description"
          content="Compétence technique - Développement et programmation de fonctionnalités et d'application web, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
        />
      </Helmet>
      <Container>
        <h1 className="skills-title">
          {translate("Compétences techniques", "Technical skills")}
        </h1>
        <h2 className="skills-sub-title">
          {translate(
            "Des acquis aux veilles technologiques",
            "From knowledge to technology watch"
          )}
        </h2>
        <p className="sentence">
          {translate(
            "Je monte en compétences pour encadrer toutes les phases d'un projet",
            "I am gaining skills to manage all phases of a project"
          )}
        </p>
        <div className="flex-center">
          <a
            href={isEnglish ? english : french}
            download={
              isEnglish ? "Resume Bilal Ezzaaraoui" : "Cv Bilal Ezzaaraoui"
            }
            className="btn-cv"
          >
            {translate("Télécharger mon CV", "Download my resume")}
          </a>
        </div>
        <div className="img-flex">
          <img src={gif} alt="gif" className="img-animation" />
        </div>

        <div className="skills">
          <h2>{translate("Arbre de compétences", "Skills tree")}</h2>
          <h3>
            {translate(
              "Zoom sur mon background de développeur Front-end",
              "Focus on my background as a front-end developer"
            )}
          </h3>
          <SkillsTree />
        </div>

        <div className="card-skills">
          <h3 className="title">
            {translate(
              `“GRÂCE AUX DIFFICULTÉS, ON SE DÉCOUVRE DES QUALITÉS INSOUPÇONNÉES.”`,
              `"THROUGH DIFFICULTIES, WE DISCOVER UNSUSPECTED QUALITIES."`
            )}
          </h3>
          <h4 className="sub-title">
            {translate(
              "Zoom sur mes qualités de développeur front-end",
              "Focus on my qualities as a front-end developer"
            )}
          </h4>

          <div className="card-layout">
            <div className="purple-20">
              <FiTarget />
              <h3>{translate("Précis", "Accurate")}</h3>
              <p>
                {translate(
                  `J'ai ce qu'on appelle le "souci du détail", c'est dans mon ADN !`,
                  `I have what we call "attention to detail", it's in my DNA!`
                )}
              </p>
            </div>
            <div className="purple-40">
              <BsLightbulbFill />
              <h3>{translate("Curieux", "Curious")}</h3>
              <p>
                {translate(
                  `J'observe "ce qu'il se fait de top", pour faire encore mieux !`,
                  `I observe "the best", to do even better!`
                )}
              </p>
            </div>
            <div className="purple-60">
              <FiTarget />
              <h3>{translate("Créatif", "Creative")}</h3>
              <p>
                {translate(
                  `Certains ont "de l'énergie a revendre", moi c'est de la
                créativité !`,
                  `Some have "energy to spare", I have "creativity"!`
                )}
              </p>
            </div>
            <div className="purple-80">
              <FiTarget />
              <h3>{translate("Autonome", "Autonomous")}</h3>
              <p>
                {translate(
                  "Je sais faire preuve d'autonomie et de rigueur dans chacune de mes missions !",
                  "I know how to show autonomy and rigor in each of my my missions!"
                )}
              </p>
            </div>
            <div className="purple-100">
              <FiTarget />
              <h3>{translate("Solutionniste", "Solution-oriented")}</h3>
              <p>
                {translate(
                  `Le plaisir d'appréhender un problème réside dans la découverte
                de sa solution !`,
                  "The pleasure of understanding a problem lies in discovering of its solution!"
                )}
              </p>
            </div>
          </div>
        </div>
        <CvPurple />
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1.25rem 0;

  .skills-title {
    color: ${colors.greenColor};
    text-transform: capitalize !important;
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    margin-top: 3rem;

    @media (max-width: 390px) {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }

  .skills-sub-title {
    font-size: 2rem;
    color: ${colors.lightColor};
    font-weight: bold;
    text-align: center;

    @media (max-width: 390px) {
      font-size: 1.5rem;
    }
  }

  .sentence {
    color: ${colors.greyColor};
    text-align: center;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    margin: 3rem 0;

    .btn-cv {
      color: ${colors.greenColor};
      border: 2px solid ${colors.greenColor};
      padding: 0.5rem 1rem;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.3s;

      &:hover {
        background-color: ${colors.greenColor};
        color: black;
      }
    }
  }

  .img-flex {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }

  .skills {
    h2 {
      margin: 5rem 0 1rem;
      font-size: 2rem;
      text-align: center;
      font-weight: bold;
      color: ${colors.lightColor};

      @media (max-width: 390px) {
        font-size: 1.5rem;
      }
    }

    h3 {
      text-align: center;
      color: ${colors.greyColor};
    }
  }

  .card-skills {
    .title {
      color: ${colors.lightColor};
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      margin-top: 3rem;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }

    .sub-title {
      font-size: 1.3rem;
      color: ${colors.greyColor};
      font-weight: bold;
      text-align: center;
      margin: 1rem 0;

      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }

    .card-layout {
      display: grid;
      margin: 3rem 0;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 2rem;

      @media (max-width: 890px) {
        overflow: scroll;
      }

      div {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 2rem;
        border-radius: 10px;
        transition: 0.3s ease-in-out;

        @media (max-width: 890px) {
          width: 15rem;
        }

        &:hover {
          transform: translateY(-5px);
        }

        svg {
          font-size: 3rem;
          color: ${colors.navyColor};
        }

        h3 {
          font-weight: bold;
          color: ${colors.navyColor};
          font-size: 1.2rem;
          margin: 0.5rem 0;
          text-align: center;
        }

        p {
          color: ${colors.navyColor};
          text-align: center;
          line-height: 1.3;
        }
      }

      .purple-20 {
        background-color: #79f7d9;
      }

      .purple-40 {
        background-color: #4fbca3;
      }

      .purple-60 {
        background-color: #349981;
      }

      .purple-80 {
        background-color: #057259;
      }

      .purple-100 {
        background-color: #01604a;
      }
    }
  }
`;

export default Skills;
