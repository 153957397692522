import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../asset/img/Header/logo-transparent.png";
import french from "../../../asset/cv/french.pdf";
import english from "../../../asset/cv/english.pdf";
import { CgMenuRightAlt } from "react-icons/cg";
import { FaFileDownload } from "react-icons/fa";
import { US, FR } from "country-flag-icons/react/3x2";
import { languageActions } from "../../../store/languageSlice/languageSlice";
import "./Header.scss";
import Modal from "./modal/Modal";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const isEnglish = useSelector((state) => state.language.isEnglish);

  const handlerModal = () => {
    setState(!state);
  };

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("isEnglish");

    if (data === "true" && typeof data === "string") {
      dispatch(languageActions.changeLanguage(true));
    } else {
      dispatch(languageActions.changeLanguage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEnglish = () => {
    if (!isEnglish) {
      localStorage.setItem("isEnglish", "true");
      dispatch(languageActions.changeLanguage(true));
    } else {
      localStorage.removeItem("isEnglish");
      dispatch(languageActions.changeLanguage(false));
    }
  };

  return (
    <header>
      <div className="language-selector">
        <div className="country-layout" onClick={() => checkEnglish()}>
          {!isEnglish && <US />}
          {isEnglish && <FR />}
        </div>
      </div>
      <div className="w-full h-20 ">
        <div className="flex justify-between max-w-7xl h-full px-5 mx-auto">
          <div className="w-fit h-full flex justify-center items-center ">
            <NavLink to="/">
              <img src={logo} alt="logo" className="w-12" />
            </NavLink>
          </div>
          <div className="w-fit h-full flex justify-center items-center laptop:hidden">
            <NavLink to="/">
              <CgMenuRightAlt
                size={40}
                className="btn-nav-menu mobile:hidden"
                onClick={handlerModal}
              />
              <CgMenuRightAlt
                size={50}
                className="hidden mobile:block btn-nav-menu"
                onClick={handlerModal}
              />
            </NavLink>
          </div>
          <div className="hidden flex-1  justify-center items-center h-full laptop:flex">
            <ul className="nav-list flex flex-wrap justify-center">
              <li className="mx-5">
                <NavLink
                  to="/services"
                  className={`nav-text-color-header font-semibold text-lg mt-2 ${(
                    navData
                  ) => (navData.isActive ? "active" : "")} `}
                >
                  {translate("Mes services", "My services")}
                </NavLink>
              </li>
              <li className="mx-5">
                <NavLink
                  to="/portfolio"
                  className={`nav-text-color-header font-semibold text-lg ${(
                    navData
                  ) => (navData.isActive ? "active" : "")} `}
                >
                  {translate("Mes projets", "My projects")}
                </NavLink>
              </li>
              <li className="mx-5">
                <NavLink
                  to="/competences"
                  className={`nav-text-color-header font-semibold text-lg ${(
                    navData
                  ) => (navData.isActive ? "active" : "")} `}
                >
                  {translate("Compétences", "Skills")}
                </NavLink>
              </li>
              <li className="mx-5">
                <NavLink
                  to="/contact"
                  className={`nav-text-color-header font-semibold text-lg  ${(
                    navData
                  ) => (navData.isActive ? "active" : "")} `}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="hidden w-fit h-full  justify-center items-center px-8 laptop:flex">
            <a
              href={isEnglish ? english : french}
              download={
                isEnglish ? "Resume Bilal Ezzaaraoui" : "Cv Bilal Ezzaaraoui"
              }
              className="link-cv flex items-center px-10 py-1 rounded  text-lg font-bold    	;
              "
            >
              <FaFileDownload className="mr-2" /> <span>CV</span>
            </a>
          </div>
        </div>
      </div>
      {state && <Modal onConfirm={handlerModal} />}
    </header>
  );
};

export default Header;
