import React, { useEffect, useState } from "react";
import styled from "styled-components";
import db from "../../firebase";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FaArrowLeft,
  FaLink,
  FaGithub,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { GoCheck } from "react-icons/go";
import color from "../../asset/style/colors";
import firebase from "../../asset/img/Homepage/LogoStack/firebase.svg";
import redux from "../../asset/img/Homepage/LogoStack/redux.svg";
import jest from "../../asset/img/Homepage/LogoStack/jest.png";
import sass from "../../asset/img/Homepage/LogoStack/sass.svg";
import react from "../../asset/img/Homepage/LogoStack/react.svg";
import js from "../../asset/img/Homepage/LogoStack/js.svg";
import styledComponents from "../../asset/img/Homepage/LogoStack/styled-components.png";
import cypress from "../../asset/img/Homepage/LogoStack/cypress.jpeg";
import rtl from "../../asset/img/Homepage/LogoStack/rtl.png";
import typescript from "../../asset/img/Homepage/LogoStack/typescript.png";
import { useSelector } from "react-redux";
import ProjectSlider from "../../components/ui/slider/Slider";

const Project = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(null);
  const isEnglish = useSelector((state) => state.language.isEnglish);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const stackImage = [
    { text: "react", image: react },
    { text: "firebase", image: firebase },
    { text: "redux", image: redux },
    { text: "jest", image: jest },
    { text: "sass", image: sass },
    { text: "javascript", image: js },
    { text: "styled-components", image: styledComponents },
    { text: "react testing library", image: rtl },
    { text: "typescript", image: typescript },
    { text: "cypress", image: cypress },
  ];

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    const searchProject = async () => {
      let portfolio = [];
      db.collection("portfolio").onSnapshot((snapshot) => {
        // eslint-disable-next-line array-callback-return
        snapshot.docs.map((doc) => {
          portfolio = [...portfolio, { id: doc.id, ...doc.data() }];
        });

        const result = portfolio.find((item) => {
          if (item.id === id) {
            return item;
          }
        });

        if (typeof result !== "undefined") {
          setState(result);
        } else {
          navigate("*");
        }
      });
    };

    searchProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };

  if (state !== null && typeof state.id !== "undefined") {
    return (
      <Fragment>
        <Helmet>
          <title>{`Projet ${state.id} - Développé par Bilal Ezzaaraoui`}</title>
          <meta
            name="description"
            content="Détail du projet - Développement et programmation de fonctionnalités et d'application web, de la landing page en passant par le SaaS, pour des petites et moyennes entreprises."
          />
        </Helmet>
        <Container>
          <div className="hero" style={{ backgroundColor: state.couleur }}>
            <div className="hero-layout">
              <div className="hero-img">
                <img src={state.logo} alt="logo" />
              </div>
              <div className="hero-btn">
                <Link to="/portfolio">
                  <div className="btn-layout">
                    <FaArrowLeft />
                    <span>{translate("Retour", "Back")}</span>
                  </div>
                </Link>
                <a
                  href={state.github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn-layout">
                    <FaGithub />
                    <span>
                      {translate(
                        "Lien github du projet",
                        "Github link of the project"
                      )}
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="projet">
            <h1 className="title">
              <span className="icon-layout">
                <MdWork />
                <span>|</span>
              </span>
              <span className="title-text">{state.id}</span>
            </h1>

            <div className="description-layout">
              <div className="description-first-bloc">
                <h3 className="description-title">
                  {translate("Détails du projet", "Project details")}
                </h3>

                <div className="details">
                  <div className="title">
                    <h3>{translate("Résumé", "Summary")}</h3>
                  </div>
                  <div className="description">
                    <p>
                      {isEnglish ? state.descriptionEn : state.descriptionFr}
                    </p>
                  </div>
                </div>
                <div className="details">
                  <div className="title">
                    <h3>
                      {translate(
                        "Fonctionnalité du projet",
                        "Functionality of the project"
                      )}
                    </h3>
                  </div>
                  <div className="description">
                    <ul>
                      {isEnglish &&
                        state.keyPointsEn.map((item, key) => (
                          <li key={key}>{item}</li>
                        ))}
                      {!isEnglish &&
                        state.keyPointsFr.map((item, key) => (
                          <li key={key}>{item}</li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="details">
                  <div className="title">
                    <h3>{translate("Année", "Year")}</h3>
                  </div>
                  <div className="description">
                    <p>{state.date}</p>
                  </div>
                </div>
              </div>
              <div className="description-second-bloc">
                <a href={state.lien} target="_blank" rel="noopener noreferrer">
                  <div className="link">
                    <FaExternalLinkAlt />
                    <span>
                      {translate(
                        "Visiter le projet demo",
                        "Visit the demo project"
                      )}
                    </span>
                  </div>
                </a>

                <div className="stack-layout">
                  <h3>{translate("Stack téchnique", "Technical Stack")}</h3>
                  {state.stack !== undefined
                    ? // eslint-disable-next-line array-callback-return
                      state.stack.map((element, index) => {
                        // eslint-disable-next-line array-callback-return
                        const res = stackImage.find((item) => {
                          if (item.text === element) {
                            return item;
                          }
                        });

                        if (res !== undefined) {
                          return (
                            <div className="stack-object" key={index}>
                              <div className="logo">
                                <img src={res.image} alt="logo" />
                              </div>
                              <div className="info">
                                <div>
                                  <span className="text">{res.text}</span>
                                </div>
                                <div>
                                  <span className="check">
                                    <GoCheck />
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
          {false && (
            <div className="projet-demo-image">
              <img src={state.background} />
            </div>
          )}
        </Container>
      </Fragment>
    );
  } else {
    return;
  }
};

const Container = styled.div`
  .hero {
    .hero-layout {
      max-width: 80rem;
      margin: 0 auto;
      padding: 2rem 1.25rem 0;
      display: flex;
      flex-direction: column;

      .hero-img {
        display: flex;
        justify-content: center;

        img {
          width: 10rem;
          margin-top: 2rem;
        }
      }

      .hero-btn {
        display: flex;
        justify-content: space-between;
        margin: 2rem 0 4rem;

        @media (max-width: 768px) {
          flex-direction: column;
          row-gap: 1rem;
        }

        a {
          font-size: 0.9rem;
          color: ${color.navyColor};

          @media (max-width: 768px) {
            display: inherit;
          }

          .btn-layout {
            padding: 0.2rem 0.8rem;
            display: flex;
            align-items: center;
            background-color: ${color.lightColor};
            border-radius: 5px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: 0.3s;

            &:hover {
              transform: translateY(-5px);
            }

            svg {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  .projet {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 1.25rem 0;

    .title {
      display: flex;

      .icon-layout {
        display: flex;
        align-items: center;

        svg {
          color: ${color.greenColor};
          font-size: 1.5rem;
        }
        span {
          color: ${color.greenColor};
          font-weight: bold;
          margin: 0 3px;
        }
      }

      .title-text {
        color: ${color.lightColor};
        font-weight: bold;
        font-size: 1.875rem;
        margin-left: 0.5rem;
        text-transform: capitalize;
      }
    }

    .description-layout {
      margin: 1.5rem 0;
      display: grid;
      grid-template-columns: repeat(16, 1fr);

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      .description-first-bloc {
        grid-column: 1 / span 11;
        padding: 1.5rem;
        background-color: ${color.navyColor};

        .description-title {
          color: ${color.lightColor};
          font-size: 1.3rem;
          margin-bottom: 1rem;
        }

        .details {
          border-top: 1px solid rgba(140, 140, 140, 0.05);
          padding: 1.25rem 1.5rem 1.25rem 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }

          .title {
            grid-column: 1 / span 1;

            h3 {
              color: ${color.lightColor};
              font-weight: bold;
            }
          }

          .description {
            grid-column: 2 / span 2;

            @media (max-width: 768px) {
              margin-top: 1rem;
            }

            ul {
              list-style: circle;
              color: ${color.greyColor};
              padding-left: 1rem;
            }

            p {
              color: ${color.greyColor};
            }

            a {
              color: ${color.greenColor};
              transition: 0.3s;

              &:hover {
                color: ${color.greenBg};
              }
            }
          }
        }
      }

      .description-second-bloc {
        grid-column: 13 / span 5;

        @media (max-width: 768px) {
          margin-top: 1rem;
        }

        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border: 1px solid ${color.greenColor};
          color: ${color.greenColor};
          padding: 3px 0;
          transition: 0.3s;

          &:hover {
            background-color: ${color.greenColor};
            color: black;
            transform: translateY(-3px);
          }

          svg {
            margin-right: 0.5rem;
          }

          span {
            font-weight: bold;
          }
        }

        .stack-layout {
          margin-top: 1rem;
          background-color: ${color.navyColor};
          border-radius: 3px;
          padding: 0.2rem 1rem;

          h3 {
            color: ${color.lightColor};
            margin: 1rem 0;
            font-weight: bold;
          }

          .stack-object {
            display: flex;
            margin-bottom: 1rem;

            .logo {
              width: 2rem;
              height: 2rem;
              background-color: $bg;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 6px;
              border-radius: 25px;
            }

            .info {
              flex: 1;
              display: flex;
              justify-content: space-between;
              padding-left: 1rem;

              div {
                display: flex;
                align-items: center;

                .text {
                  color: ${color.lightColor};
                  font-weight: normal;
                  text-transform: capitalize;
                }

                .check {
                  svg {
                    color: rgb(27, 162, 27);
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .projet-demo-image {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 1.25rem;
  }
`;

export default Project;
