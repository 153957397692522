import React from "react";
import "./Footer.scss";
import logo from "../../../asset/img/Header/logo-transparent.png";
import { Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { FaLayerGroup } from "react-icons/fa";
import { ImGithub } from "react-icons/im";
import { BsLinkedin, BsFillBriefcaseFill } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { SiUpwork } from "react-icons/si";
import { useSelector } from "react-redux";

const Footer = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);
  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };
  return (
    <footer className="w-full h-auto border-t  border-gray-400 border-opacity-25">
      <div
        className="py-5 px-5 max-w-7xl h-full mx-auto grid grid-cols-1 gap-4
        mobile:grid-rows-2 mobile:auto-rows-auto	mobile:grid-cols-3 tablet:py-5 
        tablet:px-8 tablet:grid-cols-5
        tablet:grid-rows-1
        "
      >
        <div className="w-full h-full col-span-2 mobile:col-span-3 tablet:col-span-2">
          <div className="w-full h-10">
            <img src={logo} alt="logo du footer" className="h-full" />
          </div>
          <h3 className="text-white font-bold mt-5 text-xl">EBDev</h3>
          <h2 className="text-greyText text-base mt-2 uppercase">
            Bilal Ezzaaraoui
          </h2>
          <h4 className="text-greyText text-sm mb-2 uppercase">
            {translate(
              "DEVELOPPEUR FRONT-END REACT JS",
              "FRONT-END DEVELOPER REACT JS"
            )}
          </h4>
          <p className="text-greyText text-sm">
            101 Rue des lantanas
            <br />
            83600 Fréjus
            <br />
            FRANCE
          </p>
          <h4 className="text-greyText text-sm mb-4 uppercase">
            Port. : +33 7 65 71 99 10
          </h4>
          <div className="flex mobile:hidden mb-3">
            <div className="mr-4">
              <a href="https://www.instagram.com/bilal.ezzaa/">
                <GrInstagram color="#CBD5E0" size={25} />
              </a>
            </div>
            <div className="mr-4">
              <a href="https://www.linkedin.com/in/bilalezzaa/">
                <BsLinkedin color="#CBD5E0" size={25} />
              </a>
            </div>
            <div className="mr-4">
              <a href="https://www.upwork.com">
                <SiUpwork color="#CBD5E0" size={25} />
              </a>
            </div>
          </div>
          <div className="pt-4 border-t border-gray-400 border-opacity-25">
            <p className="text-sm text-greyText flex items-center">
              © 2022 developed with
              {
                <AiFillHeart className="inline-block text-red-600 mx-1 text-sm" />
              }{" "}
              by EBDev
            </p>
            <p
              className="flex
            items-center mt-1 text-sm text-greyText"
            >
              {<FaLayerGroup className="text-xs  text-greyText mr-1" />}

              {translate(
                "Stack utilisé : React . Firebase . Styled-components",
                "Stack used : React . Firebase . Styled-components"
              )}
            </p>
          </div>
        </div>
        <div className="hidden w-full h-full mobile:flex flex-col justify-center mobile:justify-start tablet:justify-start laptop:block mt-12 ">
          <h3 className="text-white font-bold  mb-4 text-xl">Société</h3>
          <ul className="">
            <li className="mb-1 link-hover">
              <Link to="/contact" className="text-sm text-greyText">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden w-full h-full mobile:flex flex-col justify-center mobile:justify-start  tablet:justify-start laptop:block mt-12">
          <h3 className="text-white font-bold text-center  mb-4 text-xl">
            Navigation
          </h3>
          <ul className="flex flex-col items-center">
            <li className="mb-1 link-hover">
              <Link to="/services" className="text-sm text-greyText">
                {translate("Mes services", "My services")}
              </Link>
            </li>{" "}
            <li className="mb-1 link-hover">
              <Link to="/competences" className="text-sm text-greyText">
                {translate("Compétences", "Skills")}
              </Link>
            </li>
            <li className="mb-1 link-hover">
              <Link to="/portfolio" className="text-sm text-greyText">
                Portfolio
              </Link>
            </li>{" "}
          </ul>
        </div>
        <div className="hidden w-full h-full mobile:flex flex-col justify-center mobile:justify-start  tablet:justify-start laptop:block mt-12">
          <h3 className="text-white font-bold text-right  mb-4 text-xl">
            {translate("Réseaux", "Contact link")}
          </h3>
          <ul className="flex flex-col items-end">
            <li className="mb-1 link-hover">
              <a
                href="https://github.com/bilalezzaaraoui"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-greyText"
              >
                <div className="flex items-center gap-1">
                  <span>Github</span>
                  <ImGithub />
                </div>
              </a>
            </li>
            <li className="mb-1 link-hover">
              <a
                href="https://www.linkedin.com/in/bilalezzaa/"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-greyText"
              >
                <div className="flex items-center gap-1">
                  <span>Linkden</span>
                  <BsLinkedin />
                </div>
              </a>
            </li>{" "}
            <li className="mb-1 link-hover">
              <a
                href="https://www.upwork.com/freelancers/bilalezzaa"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-greyText"
              >
                <div className="flex items-center gap-1">
                  <span>Upwork</span>
                  <SiUpwork />
                </div>
              </a>
            </li>
            <li className="mb-1 link-hover">
              <a
                href="https://www.malt.fr/profile/bilalezzaa"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-greyText"
              >
                <div className="flex items-center gap-1">
                  <span>Malt</span>
                  <BsFillBriefcaseFill />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
