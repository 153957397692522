import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactDom from "react-dom";
import { IoClose } from "react-icons/io5";
import { IoMdMailOpen } from "react-icons/io";
import {
  FaLaptopHouse,
  FaPaintBrush,
  FaCodeBranch,
  FaFileDownload,
} from "react-icons/fa";
import french from "../../../../asset/cv/french.pdf";
import english from "../../../../asset/cv/english.pdf";
import "./Modal.scss";

const Menu = (props) => {
  const isEnglish = useSelector((state) => state.language.isEnglish);

  return (
    <div
      className="fixed flex flex-col z-10 w-full p-4 h-full bg-greySite"
      onClick={props.onConfirm}
    >
      <div className="flex justify-end">
        <button
          className="p-1 bg-red-600 rounded-full"
          onClick={props.onConfirm}
        >
          <IoClose size={25} color="white" />
        </button>
      </div>
      <div className="flex-1 flex flex-col w-full">
        <div className="flex justify-center my-4">
          <h3 className="text-xl text-white font-semibold opacity-90">
            Navigation
          </h3>
        </div>
        <div className="nav-mobile-menu w-full flex-1 grid grid-cols-2 gap-3 ">
          <NavLink
            to="/services"
            className=" flex flex-col justify-center items-center text-white opacity-90 border-2 border-slate-400 border-dotted"
          >
            <FaLaptopHouse size={30} />
            <p>Services</p>
          </NavLink>

          <NavLink
            to="/portfolio"
            className="flex flex-col justify-center items-center text-white opacity-90 border-2 border-slate-400 border-dotted"
          >
            <FaPaintBrush size={30} />
            <p>Portfolio</p>
          </NavLink>

          <NavLink
            to="/competences"
            className="flex flex-col justify-center items-center text-white opacity-90 border-2 border-slate-400 border-dotted"
          >
            <FaCodeBranch size={30} />
            <p>Compétences</p>
          </NavLink>
          <NavLink
            to="/contact"
            className="flex flex-col justify-center items-center text-white opacity-90 border-2 border-slate-400 border-dotted"
          >
            <IoMdMailOpen size={30} />
            <p>Contact</p>
          </NavLink>
        </div>
        <a
          href={isEnglish ? english : french}
          download={
            isEnglish ? "Resume Bilal Ezzaaraoui" : "Cv Bilal Ezzaaraoui"
          }
          className="btn-cv-mobile flex justify-center 
        mt-4 items-center px-8 py-2 rounded text-xl font-bold"
        >
          <FaFileDownload className="mr-2" />
          {isEnglish ? "Resume " : "CV"}
        </a>
      </div>
      <div />
    </div>
  );
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {ReactDom.createPortal(
        <Menu onConfirm={props.onConfirm} />,
        document.getElementById("modal-root")
      )}
    </React.Fragment>
  );
};
export default Modal;
