import { useState, useRef } from "react";
import "./ContactForm.scss";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";

const ContactForm = () => {
  const form = useRef();
  const isEnglish = useSelector((state) => state.language.isEnglish);
  const [result, setResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yi99e4g",
        "template_do54mak",
        form.current,
        "j4txpX3zRmlNQFK7f"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  const translate = (french, english) => {
    if (isEnglish) {
      return english;
    } else {
      return french;
    }
  };
  return (
    <div className="contact-form-width">
      <form ref={form} onSubmit={sendEmail}>
        <div className="first input-style">
          <label htmlFor="name">{translate("Prénom", "First name")}</label>
          <input
            type="text"
            name="name"
            required
            placeholder={translate(
              "Indique ton prénom",
              "Indicate your first name"
            )}
          />
        </div>
        <div className="sujet input-style">
          <label htmlFor="subject">{translate("Sujet", "Subject")}</label>
          <input
            type="text"
            name="subject"
            required
            placeholder={translate(
              "Que puis-je faire pour toi ?",
              "What can I do for you?"
            )}
          />
        </div>
        <div className="email input-style">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            required
            placeholder={translate(
              "Sur quelle adresse email dois-je répondre ?",
              "On which email address should I reply?"
            )}
          />
        </div>
        <div className="message input-style">
          <label htmlFor="text">Message</label>
          <textarea
            name="text"
            rows="4"
            cols="50"
            required
            placeholder={translate(
              "Entrez votre message",
              "Enter your message"
            )}
          ></textarea>
        </div>
        <div className="checkbox">
          <label htmlFor="fname">
            {translate(
              "J'accepte la politique de confidentialité.",
              "I agree to the privacy policy."
            )}
          </label>
          <input type="checkbox" required name="fname" />
        </div>
        <div className="submit">
          <button type="submit" name="fname">
            {translate("Envoyer", "Send")}
          </button>
        </div>
      </form>
      {result && (
        <p style={{ fontWeight: "bold", color: "green", marginTop: "1rem" }}>
          {translate(
            "Le message à bien été envoyé",
            "The message has been sent"
          )}
        </p>
      )}
    </div>
  );
};

export default ContactForm;
